import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import aumento1 from "../../../static/img/aumento/aumento1.jpg"
import aumento2 from "../../../static/img/aumento/aumento2.jpg"
import aumento3 from "../../../static/img/aumento/aumento3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Aumento de Liquidez"/>
            <Banner title="Aumento de Liquidez"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una empresa emisora de capitales perteneciente al
                                    sector
                                    comercial minorista, que cotiza en la Bolsa Mexicana de Valores desde los años 90,
                                    ha
                                    visto erosionar
                                    su bursatilidad hasta ubicarse en la categoría de baja bursatilidad del listado de
                                    la
                                    BMV, con una
                                    operatividad sumamente disminuida. La compañía decide retomar y apuntalar su
                                    programa de
                                    RI, después de
                                    haberlo delegado, por cuestión de costos, a sus departamentos de tesorería y
                                    contraloría.</p>
                                <p>
                                    A través del relanzamiento de su programa de RI, y de una asesoría integral en
                                    finanzas corporativas
                                    y técnicas de trading, la empresa persigue reposicionar su acción a un rango donde
                                    pueda obtener la
                                    liquidez y valuación necesaria para poder realizar un follow-on, que a su vez le
                                    permita obtener los
                                    recursos necesarios para avanzar en la implementación de su plan de negocios.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={aumento1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={aumento2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>Por su capacidad probada y experimentado equipo, se
                                    eligió a
                                    IRStrat como la mejorar opción para liderar el proyecto de reposicionamiento de la
                                    acción de la empresa,
                                    a través de su solución de Aumento de Liquidez a la medida, con el mandato de
                                    diseñar y
                                    ejecutar
                                    estrategias de trading, emisión, prospección y ampliación de base de inversionistas,
                                    así
                                    como de mejorar
                                    la visibilidad y percepción de la empresa en los mercados, en pro de obtener una
                                    mejor
                                    liquidez y
                                    valuación.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat lleva a cabo una evaluación
                                            integral de todos los
                                            factores que confluyen en la operatividad de la acción (programa de RI,
                                            concentración de la
                                            operación, constitución del float y percepción de la emisora en los
                                            mercados). Con la información
                                            recabada, IRStrat constituye un plan de acción para reposicionar a nuestro
                                            cliente y ponerlo a la
                                            vanguardia en los mercados. Asimismo, se define una agenda de trabajo
                                            detallada, que contiene
                                            objetivos claros de operatividad (volumen, número de operaciones y mediana
                                            de operación),
                                            visibilidad (recomendaciones de inversión y base de cobertura de analistas),
                                            y valuaciones (P/VL y
                                            VE/UAFIDA).</p>
                                            <p>Nuestro equipo, de la mano del equipo directivo, comienza a desarrollar
                                                la tesis de inversión,
                                                historia y ventaja competitiva de la Compañía, así como el estilo de
                                                comunicación (mix
                                                cualitativo y cuantitativo), y una óptima estrategia de prospección y
                                                divulgación.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>Se lleva a cabo un estudio de percepción
                                            detallado, que permite
                                            identificar las áreas de mejora críticas, y se produce un proxy de tenencia,
                                            que delinee a
                                            profundidad el perfil de los inversionistas actuales de la emisora. Con la
                                            inteligencia obtenida,
                                            se define la estrategia de trading, i.e. operación del fondo de recompra,
                                            operación de un formador
                                            de mercado, así como el diseño de otras técnicas de tesorería alternativas.
                                            De la mano, se
                                            establece una óptima estrategia de prospección y divulgación, que engloba
                                            una intensa agenda de
                                            presentaciones a las áreas de análisis y promoción de las diferentes casas
                                            de bolsa, enfocándose
                                            en ampliar el conocimiento e interés en la empresa y su acción, así como en
                                            ampliar la base de las
                                            coberturas de análisis.</p>
                                            <p>IRStrat conduce un entrenamiento exhaustivo del equipo ejecutivo de
                                                nuestro cliente en
                                                comunicación financiera. Se desarrollan a conciencia todos los
                                                materiales de RI bajo una óptima
                                                calidad (reporte trimestral de resultados, reporte e informe anual,
                                                presentación a
                                                inversionistas, sitio de RI e investment fact sheet). Asimismo, se
                                                desarrolla y comunica
                                                ampliamente la guía de resultados de corto y largo plazo.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Nuestro cliente aumenta de manera sustantiva la
                                            valuación y operatividad
                                            de su acción, y apuntala su posicionamiento en los mercados, permitiéndole
                                            ejecutar exitosamente
                                            un follow-on por US$450 millones, que a su vez le permite reforzar su
                                            operatividad, en un círculo
                                            virtuoso. Al término del follow-on cuenta con una base de coberturas de más
                                            de 10 analistas, entre
                                            los que se encuentran los brokerages más influyentes a nivel local e
                                            internacional.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={aumento3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
